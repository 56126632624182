// actionTypes.js

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const VERIFY_LOGIN_REQUEST = 'VERIFY_LOGIN_REQUEST';
export const VERIFY_LOGIN_SUCCESS = 'VERIFY_LOGIN_SUCCESS';
export const VERIFY_LOGIN_FAILURE = 'VERIFY_LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';


export const CHAT_USER_MESSAGE_REQUEST = 'CHAT_USER_MESSAGE_REQUEST';
export const CHAT_USER_MESSAGE_SUCCESS = 'CHAT_USER_MESSAGE_SUCCESS';
export const CHAT_USER_MESSAGE_FAILURE = 'CHAT_USER_MESSAGE_FAILURE';


export const CHAT_AGENT_POLL_REQUEST = 'CHAT_AGENT_POLL_REQUEST';
export const CHAT_AGENT_POLL_SUCCESS = 'CHAT_AGENT_POLL_SUCCESS';
export const CHAT_AGENT_POLL_FAILURE = 'CHAT_AGENT_POLL_FAILURE';

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUES'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE'


export const SET_USER_DETAILS_REQUEST = 'SET_USER_DETAILS_REQUES'
export const SET_USER_DETAILS_SUCCESS = 'SET_USER_DETAILS_SUCCESS'
export const SET_USER_DETAILS_FAILURE = 'SET_USER_DETAILS_FAILURE'

export const GET_USER_MEDICAL_DETAILS_REQUEST = 'GET_USER_MEDICAL_DETAILS_REQUES'
export const GET_USER_MEDICAL_DETAILS_SUCCESS = 'GET_USER_MEDICAL_DETAILS_SUCCESS'
export const GET_USER_MEDICAL_DETAILS_FAILURE = 'GET_USER_MEDICAL_DETAILS_FAILURE'


export const SET_USER_MEDICAL_DETAILS_REQUEST = 'SET_USER_MEDICAL_DETAILS_REQUES'
export const SET_USER_MEDICAL_DETAILS_SUCCESS = 'SET_USER_MEDICAL_DETAILS_SUCCESS'
export const SET_USER_MEDICAL_DETAILS_FAILURE = 'SET_USER_MEDICAL_DETAILS_FAILURE'

export const ADD_NOTIFICATION_REQUEST = 'ADD_NOTIFICATION_REQUEST';
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS';
export const ADD_NOTIFICATION_FAILURE = 'ADD_NOTIFICATION_FAILURE';

export const CANCEL_NOTIFICATION_REQUEST = 'CANCEL_NOTIFICATION_REQUEST';
export const CANCEL_NOTIFICATION_SUCCESS = 'CANCEL_NOTIFICATION_SUCCESS';
export const CANCEL_NOTIFICATION_FAILURE = 'CANCEL_NOTIFICATION_FAILURE';

export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
